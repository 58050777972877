import React from "react";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "@themesberg/react-bootstrap";

import { CounterWidget } from "../../components/Widgets";
import { useSelector } from "react-redux";
import { Enums } from "../../constants/Enums";

export default function DashboardOverview() {
  document.title = `${process.env.REACT_APP_NAME} | Dashboard`;

  const UserData = useSelector((state) => state.data);
  let logged_user = UserData.logged_user;

  return (
    <>
      <Row className="justify-content-md-start">
        <Col xs={12} sm={6} xl={3} className="mb-4">
          {logged_user.user_type.toLowerCase() ===
            Enums.USER_TYPES.super_admin ||
          logged_user.user_type.toLowerCase() === Enums.USER_TYPES.admin ? (
            <CounterWidget
              category="Agents"
              title="11"
              icon={faUser}
              iconColor="shape-secondary"
            />
          ) : (
            ""
          )}
        </Col>
      </Row>
    </>
  );
}
