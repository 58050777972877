export const Options = {
    genders: [
        { value: '', label: 'Select Gender' },
        { value: 'male', label: 'male' },
        { value: 'female ', label: 'female' },
        { value: 'other', label: 'other' }
    ],
    marital_status: [
        { value: '', label: 'Select Marital Status' },
        { value: 'single', label: 'single' },
        { value: 'married ', label: 'married' },
        { value: 'widowed', label: 'widowed' },
        { value: 'divorced', label: 'divorced' },
        { value: 'separated', label: 'separated' },
    ],
    countries: [
        { label: 'Select...', value: '' },
        { label: 'United States', value: 'USA' },
    ],
    states: [
        { label: 'Select...', value: '' },
        { label: 'California', value: 'california' },
    ],
    insurance_status: [
        { label: 'Pending', value: 'pending' },
        { label: 'In Progress', value: 'in_progress' },
        { label: 'Rejected', value: 'rejected' },
        { label: 'Accepted', value: 'accepted' },
        { label: 'Quotation Sent', value: 'quotation_sent' },
        { label: 'Confirmed', value: 'confirmed' },
        { label: 'Payment Completed', value: 'payment_completed' },
        { label: 'Completed', value: 'completed' },
    ]
};
