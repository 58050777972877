import React from 'react'

export default function PageLoader() {
    return (<div className='w-100 d-flex justify-content-center align-items-center'>
        <div className="loading-spinner-ripple">
            <div className="loading-inner">
                <div></div>
                <div></div>
            </div>
        </div>
    </div>)
}
