import axios from "axios";
import { alerts } from "../common/alertService";
import ApiTypes from "./APItypes";
import getRequestBody from "./GetRequestBody";

let cancelTokensHashMap = new Map();

export default async function APICall({
  url,
  method = "post",
  data = null,
  param = null,
  headers = null
}) {
  const WithoutCancelToken = [
    ApiTypes.fetchTerms,
    ApiTypes.fetchCategoryTypesOptions,
    ApiTypes.fetchTermListByCategory,
    ApiTypes.fetchVideoAssociations
  ];

  try {
    // Cancel previous requests for the same URL
    if (cancelTokensHashMap.has(url)) {
      cancelTokensHashMap.get(url).cancel();
      cancelTokensHashMap.delete(url);
    }

    let cancelToken = null;
    if (!WithoutCancelToken.includes(url)) {
      cancelToken = axios.CancelToken.source();
      cancelTokensHashMap.set(url, cancelToken);
    }

    // Making the API request
    const response = await axios(
      getRequestBody(url, data, param, cancelToken, method, headers)
    );
    if (response.data.status === 0) {
      alerts.notifyError(response.data.message);
      return false;
    } else {
      return response.data;
    }

  } catch (e) {
    if (axios.isCancel(e)) {
      return false;
    }
    if (e.response) {
      switch (e.response.status) {
        case 500:
          alerts.notifyError("Internal server error.");
          break;
        case 400:
          alerts.notifyError(e.response.message);
          break;
        case 404:
          alerts.notifyError("Not found");
          break;
        case 401:
          alerts.notifyError("You are unauthorized.");
          break;
        case 403:
          alerts.notifyError(e.response.data.message);
          break;
        default:
          alerts.notifyError(e.response.message);
          break;
      }
    } else {
      alerts.notifyError(e.message);
    }
    return false;
  }
}
