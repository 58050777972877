import React, { useState } from "react";
import {
  Form,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as EyeIcon } from "../../assets/Images/Icons/eye.svg";
import { ReactComponent as EyeSlashIcon } from "../../assets/Images/Icons/eyeSlash.svg";

export default function InputElement({ maxLength = '', inputClass = '', disabled = false, is_required = false, id = null, name, min = "", max = "", value, type, onChange, inputGroupClass = "mb-4", Label = null, inputIcon = null, autoFocus = false, required = false, placeholder = "", validateError = '' }) {

  const [passHide, setPassHide] = useState(true);

  let hideShowIcon = false;

  if (type === "password") {

    hideShowIcon = true;
  }

  return (<Form.Group id={id ? id : null} className={`mb-4${inputGroupClass ? ` ${inputGroupClass}` : ""} `}>
    {Label ? <Form.Label>{Label} {is_required && <span className="text-danger">*</span>}</Form.Label> : null}
    <InputGroup>
      {inputIcon ? <InputGroup.Text>
        <FontAwesomeIcon icon={inputIcon} />
      </InputGroup.Text> : null}
      <Form.Control
        onChange={(e) => {
          let input_value = e.target.value ? e.target.value : "";
          if (type === 'number') {
            if (e.target.value) {
              if (e.target.value < 0) {
                input_value = ""
              } else {
                if (maxLength && e.target.value.length > maxLength) {
                  input_value = value
                }
              }
            }
          }
          let name = e.target.name ? e.target.name : ""
          onChange(name, input_value)
        }}
        onWheelCapture={(e) => {
          if (type === 'number')
            e.target.blur()
        }}
        min={min}
        max={max}
        maxLength={maxLength}
        disabled={disabled}
        value={value}
        className={`${inputClass} ${hideShowIcon && "pe-5"}`}
        autoFocus={autoFocus}
        name={name}
        type={hideShowIcon ? passHide ? 'password' : 'text' : type}
        required={required}
        placeholder={placeholder}
      />
      {hideShowIcon ? passHide ? <EyeIcon onClick={() => setPassHide(false)} className="cursor-pointer position-absolute me-3 end-0 top-26" />
        : <EyeSlashIcon onClick={() => setPassHide(true)} className="cursor-pointer position-absolute me-3 end-0 top-26" />
        : ""}
    </InputGroup>
    {validateError && <span className="text-danger">{validateError} </span>}
  </Form.Group>)
}
