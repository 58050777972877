import React, { useEffect, useState, useCallback } from 'react'
// import { useNavigate } from 'react-router-dom';
import DataTable from "react-data-table-component";
import { alerts } from '../../common/alertService';
import { commonHelper } from '../../common/commonHelper';
import ApiTypes from '../../networking/APItypes';
import APICall from '../../networking/AxiousServices';
import {
  faPlus, faEdit, faTrash,
  // faEye 
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card } from 'react-bootstrap';
import DefaultUserImage from "../../assets/Images/defaults/default-image.png";
import Switch from "react-switch";
import InputElement from '../../components/Forms/InputElement';
import { Enums } from '../../constants/Enums';
import TableLoader from '../../loaders/TableLoader';
import { useNavigate } from 'react-router-dom';
import Routes from '../../routes';

export default function AgentList() {

  document.title = `${process.env.REACT_APP_NAME} | Agent List`;

  const navigate = useNavigate();
  const [pageNo, setPageNo] = useState(1);
  const [perPageRecords, setPerPageRecords] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [list, setList] = useState({
    isData: false,
    data: [],
  });
  const [dataTableLoading, setDataTableLoading] = useState(true);
  const [txtSearch, setTxtSearch] = useState("");

  const onSwitchToggle = async (value, item, index) => {
    let data = {
      agent_id: item.agent_id,
      is_active: value,
    }
    let res = await APICall({ url: ApiTypes.agentUpdateStatus, method: 'PUT', data: data })
    if (res.status === 1) {
      setList((prevState) => ({
        ...prevState,
        data: prevState.data.map((MapItem, MapIndex) => ({
          ...MapItem,
          is_active:
            MapIndex === index ? (value ? "1" : "0") : MapItem.is_active,
        })),
      }));

      alerts.notifySuccess(res.message)
    }
  };

  const fetchList = useCallback(async (column = "", sortDirection = "") => {
    let data = {
      page: pageNo,
      limit: perPageRecords,
      order: { column: column, dir: sortDirection },
      filter: {
        search: txtSearch
      }
    }
    let res = await APICall({
      url: ApiTypes.agentList, data: data
    })
    if (res.status === 1) {
      setList({ isData: true, data: res.data.list });
      setTotalRows(res.data.total)
      setDataTableLoading(false);

    }
  }, [txtSearch, pageNo, perPageRecords])

  const handleSort = async (column, sortDirection) => {
    fetchList(column.sortField, sortDirection);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPageRecords(newPerPage);
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const deleteAgent = async (agentId, fetchList) => {
    try {
      const response = await APICall({
        url: ApiTypes.agentDelete.replace(':id', agentId),
        method: 'DELETE',
      });

      if (response.status === 1) {
        fetchList('', '');
        alerts.notifySuccess(response.message);
      }
    } catch (error) {
      console.error("Error deleting agent:", error);
      alerts.notifyError("An error occurred while trying to delete the agent.");
    }
  };

  const columns = [
    {
      name: "#",
      selector: (row, i) => (pageNo === 1 ? i + 1 : (pageNo - 1) * perPageRecords + (i + 1)),
      sortable: true,
      width: "60px",
      sortField: "created_at",
    },
    {
      name: "Profile",
      width: "90px",
      selector: (row) => <img alt='Agent Profile' className='img-list' src={row.profile_img ? process.env.REACT_APP_ROOT_URL + row.profile_img : DefaultUserImage}></img>,
      sortable: false,
    },
    {
      name: "Agent Name",
      selector: (row) => <span className='cursor-pointer nav-link' onClick={() => {
        // navigate(Routes.AgentView.path.replace(':id', row.agent_id))
      }}>{row.display_name}</span>,
      sortable: true,
      sortField: "display_name",
    },
    {
      name: "Email",
      selector: (row) => <span className='cursor-pointer' title={row.email}>{row.email}</span>,
      sortable: true,
      width: "180px",
      sortField: "email",
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone,
      sortable: true,
      width: "180px",
      sortField: "phone",
    },
    {
      name: "Gender",
      selector: (row) => Enums.GENDER_VALUES[row.gender.toLowerCase()],
      sortable: true,
      sortField: "gender",
    },
    {
      name: "Date of Birth",
      selector: (row) => commonHelper.formatDate(row.date_of_birth),
      sortable: true,
      sortField: "date_of_birth",
    },
    {
      name: "Date of Joining",
      selector: (row) => commonHelper.formatDate(row.joining_date),
      sortable: true,
      sortField: "joining_date",
    },
    {
      name: "Total Customers",
      selector: (row) => row.total_customers,
      sortable: false,
      sortField: "joining_date",
    },
    {
      name: "Status",
      selector: (row, index) => <Switch
        uncheckedIcon={false}
        checkedIcon={false}
        onChange={(value) => onSwitchToggle(value, row, index)}
        checked={parseInt(row.is_active) === 1 ? true : false}
        height={18}
        width={36}
        onColor="#11921f"
      />,
      sortable: true,
      width: '90px',
      sortField: "is_active",
    }
    ,
    {
      name: "Action",
      width: "100px",
      sortable: false,
      cell: (row) => <span>
        {/* <FontAwesomeIcon onClick={() => {
          navigate(Routes.AgentView.path.replace(':id', row.agent_id))
        }} className='cursor-pointer' icon={faEye} /> */}
        <FontAwesomeIcon onClick={() => {
          navigate(Routes.AgentDetails.path.replace(':id', row.agent_id))
        }} className='cursor-pointer' icon={faEdit} />
        <FontAwesomeIcon onClick={() => deleteAgent(row.agent_id, fetchList)}
          className='cursor-pointer text-danger ms-2' icon={faTrash} />

      </span>
    }
  ];

  useEffect(() => {
    fetchList("", "")
  }, [fetchList])

  return (<>
    <Card className="p-4 mb-4">
      <div className='container-fluid p-0'>
        <div className="row d-flex justify-content-between align-items-center"><div className="col-4"></div>
          <div className="col-2 d-flex justify-content-end"></div>
        </div>
        <div className='col-4'>
          <div className="h6">Agent List</div>
        </div>
        <div className='search-col-top d-flex justify-content-end mb-4'>
          <InputElement
            is_required={false}
            value={txtSearch}
            inputClass={"custom-table-search"}
            name="search"
            onChange={(name, value) => {
              setTxtSearch(value)
              if (value) {
                setResetPaginationToggle(true)
              } else {
                setResetPaginationToggle(false)
              }
            }}
            type="text"
            placeholder="Search"
          />

          <div className='plus-icon-button ms-3'>
            <Button className="customizebtn" type="button" onClick={() => navigate(Routes.AgentAdd.path)} variant="theme" >
              <FontAwesomeIcon icon={faPlus} /> Add Agent
            </Button>
          </div>
        </div>
        <div className="z-adjust-for-dropdown">
          <DataTable
            columns={columns}
            fixedHeader
            fixedHeaderScrollHeight="440px"
            data={list.data}
            progressPending={dataTableLoading}
            progressComponent={<TableLoader columns={columns} />}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            onSort={handleSort}
          />
        </div>
      </div>
    </Card></>)
}
