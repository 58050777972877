import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react'
import {
  // useNavigate,
  useParams
} from 'react-router-dom';
import PageLoader from '../../../loaders/PageLoader';
import ApiTypes from '../../../networking/APItypes';
import APICall from '../../../networking/AxiousServices';
import { Card } from 'react-bootstrap';
import { Badges } from '../../../constants/Badges';
import { Enums } from '../../../constants/Enums';
import { commonHelper } from '../../../common/commonHelper';
// import Routes from '../../../routes';
import Carousel from 'react-bootstrap/Carousel';
import StatusUpdateModel from '../StatusUpdateModel/StatusUpdateModel';

export default function AutoInsuranceView() {

  document.title = `${process.env.REACT_APP_NAME} | Auto Quotation Details`;

  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [insuranceDetails, setInsuranceDetails] = useState(null);

  const [customerInsuranceId, setCustomerInsuranceId] = useState(null);
  const [showInsuranceStatusModel, setShowInsuranceStatusModel] = useState(false);
  const [updateCount, setUpdateCount] = useState(1);
  // const navigate = useNavigate();

  const fetchDetails = async (insurance_id) => {
    let res = await APICall({
      url: ApiTypes.insuranceDetails,
      data: {
        customer_insurance_id: insurance_id
      }
    })

    if (res.status === 1) {
      setLoading(false)
      setInsuranceDetails(res.data)
      setCustomerInsuranceId(res.data.customer_insurance_id)
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchDetails(id)
  }, [id, updateCount]);

  return !loading ? (
    <Card className="p-4 mb-4">

      {
        insuranceDetails ?
          (<div className='container-fluid p-0'>
            <div className='row mb-4'>
              <div className='col-12 d-flex justify-content-between mb-4'>
                <div className='h6'>Customer Details</div>
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <span className='quotation-status'>Quotation Status :</span> {Badges.INSURANCE_STATUS[insuranceDetails.status.toLowerCase()]}
                  </div>
                  <div className='ms-5'>
                    <button onClick={() => setShowInsuranceStatusModel(true)} className='btn btn-theme'>Update Status</button>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Customer Name</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_user_details ? (insuranceDetails.customer_user_details.display_name ? insuranceDetails.customer_user_details.display_name : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Email Address</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_user_details ? (insuranceDetails.customer_user_details.email ? insuranceDetails.customer_user_details.email : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Phone Number</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_user_details ? (insuranceDetails.customer_user_details.phone ? insuranceDetails.customer_user_details.phone : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Alternate Number</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_user_details ? (insuranceDetails.customer_user_details.alternate_phone ? insuranceDetails.customer_user_details.alternate_phone : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Address</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_user_details ? (insuranceDetails.customer_user_details.address ? insuranceDetails.customer_user_details.address : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Gender</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_user_details ? (insuranceDetails.customer_user_details.gender ? Enums.GENDER_VALUES[insuranceDetails.customer_user_details.gender.toLowerCase()] : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Date Of Birth</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_user_details ? (insuranceDetails.customer_user_details.date_of_birth ? commonHelper.formatDate(insuranceDetails.customer_user_details.date_of_birth) : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Marital Status</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_details ? (insuranceDetails.customer_details.maritial_status ? Enums.MARITAL_STATUS_VALUES[insuranceDetails.customer_details.maritial_status.toLowerCase()] : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Own or Rent Home</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_details ? Badges.BOOLEAN[parseInt(insuranceDetails.customer_details.is_own_home)] : Badges.BOOLEAN[0]}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Currently Insured</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_details ? Badges.BOOLEAN[parseInt(insuranceDetails.customer_details.has_any_insurance)] : Badges.BOOLEAN[0]}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Date of Last Insurance</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_details ? (insuranceDetails.customer_details.date_of_last_insurance ? commonHelper.formatDate(insuranceDetails.customer_details.date_of_last_insurance) : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Current Career</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_details ? (insuranceDetails.customer_details.current_carreer ? insuranceDetails.customer_details.current_carreer : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Reference</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_details ? (insuranceDetails.customer_details.hear_about_us_options_label ? insuranceDetails.customer_details.hear_about_us_options_label : '-') : '-'}</div>
                  </div>
                </div>
              </div>

              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Payment Link</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.payment_link ? insuranceDetails.payment_link : "-"}</div>
                  </div>
                </div>
              </div>

              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Quotation Link</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.quote_link ? insuranceDetails.quote_link : "-"}</div>
                  </div>
                </div>
              </div>

              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Agent Name</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.agent_details ? <span onClick={() => {
                      // navigate(Routes.AgentView.path.replace(':id', insuranceDetails.agent_details.agent_id))
                    }} className='nav-link cursor-pointer'>{insuranceDetails.agent_details.display_name}</span> : '-'}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='border-top-line mb-4'></div>
            <div className='row'>
              <div className='border-right-line col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-4'>
                <div className='col-12 d-flex justify-content-between mb-1'>
                  <div className='h6'>Coverage Options</div>
                </div>
                {insuranceDetails.auto_coverage_options.length ?
                  <Carousel interval={null} indicators={insuranceDetails.auto_coverage_options.length === 1 ? false : true} controls={false} variant="dark">

                    {insuranceDetails.auto_coverage_options.map((auto_option, i) => (<Carousel.Item key={i}>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Bodily Injury Liability</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{auto_option.bodily_injury_liability ? auto_option.bodily_injury_liability : '-'}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Property Damage Liability</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{auto_option.property_damage_liability ? auto_option.property_damage_liability : '-'}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Uninsured Motorist Bodily Injury</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{auto_option.uninsured_motorist_bodily_injury ? auto_option.uninsured_motorist_bodily_injury : '-'}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Uninsured Motorist Property Damage</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{auto_option.uninsured_motorist_property_damage ? auto_option.uninsured_motorist_property_damage : '-'}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Underinsured Motorist Bodily Injury</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{auto_option.underinsured_motorist_bodily_injury ? auto_option.underinsured_motorist_bodily_injury : '-'}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Medical Pay/PIP</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{auto_option.medical_pay_pip ? auto_option.medical_pay_pip : '-'}</div>
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>))}

                  </Carousel> :
                  <>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Bodily Injury Liability</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Property Damage Liability</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Uninsured Motorist Bodily Injury</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Uninsured Motorist Property Damage</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Underinsured Motorist Bodily Injury</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Medical Pay/PIP</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                  </>
                }
              </div>
              <div className='border-right-line col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-4'>
                <div className='col-12 d-flex justify-content-between mb-1'>
                  <div className='h6'>Vehicle Information</div>
                </div>

                {insuranceDetails.vehicle_information.length ?
                  <Carousel interval={null} indicators={insuranceDetails.vehicle_information.length === 1 ? false : true} controls={false} variant="dark">

                    {insuranceDetails.vehicle_information.map((vehicle_info, i) => (<Carousel.Item key={i}>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Year</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{vehicle_info.year ? vehicle_info.year : "-"}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Make</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{vehicle_info.make_name ? vehicle_info.make_name : "-"}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Model</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{vehicle_info.modal_name ? vehicle_info.modal_name : "-"}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>VIN Number</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{vehicle_info.vin_number ? vehicle_info.vin_number : "-"}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Annual Mileage</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{vehicle_info.annual_mileage ? vehicle_info.annual_mileage : "-"}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Drive to work or school</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{vehicle_info.is_drive_to_work_or_school ? Badges.BOOLEAN[parseInt(vehicle_info.is_drive_to_work_or_school)] : Badges.BOOLEAN[0]}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Number of miles (one way)</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{vehicle_info.number_of_miles_one_way ? vehicle_info.number_of_miles_one_way : "-"}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Days per week</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{vehicle_info.days_per_week ? vehicle_info.days_per_week : "-"}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Comprehensive Deductible</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{vehicle_info.comprehensive_deductible ? vehicle_info.comprehensive_deductible : "-"}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Collision Deductible</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{vehicle_info.collision_deductible ? vehicle_info.collision_deductible : "-"}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Towing</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{vehicle_info.towing ? vehicle_info.towing : "-"}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Rental</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{vehicle_info.rental ? vehicle_info.rental : "-"}</div>
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>))}
                  </Carousel>
                  : <>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Year</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Make</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Model</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>VIN Number</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Annual Mileage</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Drive to work or school</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Number of miles (one way)</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Days per week</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Comprehensive Deductible</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Collision Deductible</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Towing</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Rental</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div></>}
              </div>
              <div className='border-right-line col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-4'>
                <div className='col-12 d-flex justify-content-between mb-1'>
                  <div className='h6'>Driver Information</div>
                </div>
                {insuranceDetails.auto_driver_information.length ?
                  <Carousel interval={null} indicators={insuranceDetails.auto_driver_information.length === 1 ? false : true} controls={false} variant="dark">
                    {insuranceDetails.auto_driver_information.map((driver_info, i) => (<Carousel.Item key={i}>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Full Name</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{driver_info.full_name ? driver_info.full_name : ""}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Vehicle Used</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{driver_info.vehicle_used ? driver_info.vehicle_used : ""}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Relationship</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{driver_info.relationship ? driver_info.relationship : ""}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Gender</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{driver_info.gender ? Enums.GENDER_VALUES[driver_info.gender] : ""}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Date Of Birth</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{driver_info.date_of_birth ? commonHelper.formatDate(driver_info.date_of_birth) : ""}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Marital Status</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{driver_info.marital_status ? Enums.MARITAL_STATUS_VALUES[driver_info.marital_status.toLowerCase()] : ""}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Percent Use</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{driver_info.percent_use ? driver_info.percent_use : ""}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Driver Licence Number</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{driver_info.driver_license_number ? driver_info.driver_license_number : ""}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>State Issued</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{driver_info.state_issued ? driver_info.state_issued : ""}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>SR22 Required</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{driver_info.sr22_required ? Badges.BOOLEAN[parseInt(driver_info.sr22_required)] : Badges.BOOLEAN[0]}</div>
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>))}
                  </Carousel>
                  : <>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Full Name</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Vehicle Used</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Relationship</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Gender</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Date Of Birth</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Marital Status</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Percent Use</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Driver Licence Number</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>State Issued</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>SR22 Required</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                  </>}
              </div>
              <div className='border-right-line col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-4'>
                <div className='col-12 d-flex justify-content-between mb-1'>
                  <div className='h6'>Violations</div>
                </div>
                {insuranceDetails.auto_violations.length ?
                  <Carousel interval={null} indicators={insuranceDetails.auto_violations.length === 1 ? false : true} controls={false} variant="dark">
                    {insuranceDetails.auto_violations.map((auto_vio, i) => (<Carousel.Item key={i}>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Violation Type</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{auto_vio.violation_options_label ? auto_vio.violation_options_label : '-'}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Driver</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{auto_vio.driver ? auto_vio.driver : '-'}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Date Occurred</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{auto_vio.date_occurred ? commonHelper.formatDate(auto_vio.date_occurred) : '-'}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Additional Information</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{auto_vio.additional_information ? auto_vio.additional_information : '-'}</div>
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>))}
                  </Carousel> : <>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Violation Type</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Driver</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Date Occurred</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Additional Information</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                  </>}
              </div>
            </div>
            {showInsuranceStatusModel && <StatusUpdateModel customerInsuranceId={customerInsuranceId} showInsuranceStatusModel={showInsuranceStatusModel} setShowInsuranceStatusModel={setShowInsuranceStatusModel} initialData={{
              status: insuranceDetails.status,
              payment_link: insuranceDetails.payment_link,
              quote_link: insuranceDetails.quote_link
            }} setUpdateCount={setUpdateCount} updateCount={updateCount} />}
          </div>
          )
          : <div className='text-center w-100 h6'> Insurance Details Not Found</div>
      }
    </Card>
  ) : <PageLoader />;
}
