import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react'
import {
  // useNavigate,
  useParams
} from 'react-router-dom';
import PageLoader from '../../../loaders/PageLoader';
import ApiTypes from '../../../networking/APItypes';
import APICall from '../../../networking/AxiousServices';
import { Card } from 'react-bootstrap';
import { Badges } from '../../../constants/Badges';
// import Routes from '../../../routes';
import Carousel from 'react-bootstrap/Carousel';
import StatusUpdateModel from '../StatusUpdateModel/StatusUpdateModel';

export default function BusinessOwnerView() {

  document.title = `${process.env.REACT_APP_NAME} | Business Owner Quotation Details`;

  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [insuranceDetails, setInsuranceDetails] = useState(null);

  const [customerInsuranceId, setCustomerInsuranceId] = useState(null);
  const [showInsuranceStatusModel, setShowInsuranceStatusModel] = useState(false);
  const [updateCount, setUpdateCount] = useState(1);

  // const navigate = useNavigate();

  const fetchDetails = async (insurance_id) => {
    let res = await APICall({
      url: ApiTypes.insuranceDetails,
      data: {
        customer_insurance_id: insurance_id
      }
    })

    if (res.status === 1) {
      setLoading(false)
      setInsuranceDetails(res.data)
      setCustomerInsuranceId(res.data.customer_insurance_id)
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchDetails(id)
  }, [id, updateCount]);

  return !loading ? (
    <Card className="p-4 mb-4">

      {
        insuranceDetails ?
          (<div className='container-fluid p-0'>
            <div className='row mb-4'>
              <div className='col-12 d-flex justify-content-between mb-4'>
                <div className='h6'>Company Details</div>
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <span className='quotation-status'>Quotation Status :</span> {Badges.INSURANCE_STATUS[insuranceDetails.status.toLowerCase()]}
                  </div>
                  <div className='ms-5'>
                    <button onClick={() => setShowInsuranceStatusModel(true)} className='btn btn-theme'>Update Status</button>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Company Name</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_user_details ? (insuranceDetails.customer_user_details.display_name ? insuranceDetails.customer_user_details.display_name : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Email Address</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_user_details ? (insuranceDetails.customer_user_details.email ? insuranceDetails.customer_user_details.email : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Phone Number</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_user_details ? (insuranceDetails.customer_user_details.phone ? insuranceDetails.customer_user_details.phone : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Alternate Number</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_user_details ? (insuranceDetails.customer_user_details.alternate_phone ? insuranceDetails.customer_user_details.alternate_phone : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Address</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_user_details ? (insuranceDetails.customer_user_details.address ? insuranceDetails.customer_user_details.address : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Payment Link</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.payment_link ? insuranceDetails.payment_link : "-"}</div>
                  </div>
                </div>
              </div>

              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Quotation Link</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.quote_link ? insuranceDetails.quote_link : "-"}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Agent Name</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.agent_details ? <span onClick={() => {
                      // navigate(Routes.AgentView.path.replace(':id', insuranceDetails.agent_details.agent_id))
                    }} className='nav-link cursor-pointer'>{insuranceDetails.agent_details.display_name}</span> : '-'}</div>
                  </div>
                </div>
              </div>


            </div>

            <div className='border-top-line mb-4'></div>
            <div className='row'>
              <div className='border-right-line col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-4'>
                <div className='col-12 d-flex justify-content-between mb-1'>
                  <div className='h6'>Owner Details</div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>First Name</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.company_owner_information ? (insuranceDetails.company_owner_information.first_name ? insuranceDetails.company_owner_information.first_name : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Last Name</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.company_owner_information ? (insuranceDetails.company_owner_information.last_name ? insuranceDetails.company_owner_information.last_name : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Nature of Business</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.company_owner_information ? (insuranceDetails.company_owner_information.nature_of_business ? insuranceDetails.company_owner_information.nature_of_business : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Number of Owners</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.company_owner_information ? (insuranceDetails.company_owner_information.num_owners ? insuranceDetails.company_owner_information.num_owners : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Gross Annual Sales</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.company_owner_information ? (insuranceDetails.company_owner_information.gross_annual_sales ? insuranceDetails.company_owner_information.gross_annual_sales : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Number of Employees</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.company_owner_information ? (insuranceDetails.company_owner_information.num_employees ? insuranceDetails.company_owner_information.num_employees : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Annual Employee Payroll</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.company_owner_information ? (insuranceDetails.company_owner_information.annual_employee_payroll ? insuranceDetails.company_owner_information.annual_employee_payroll : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Subcontractors Used</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.company_owner_information ? (insuranceDetails.company_owner_information.subcontractors_used ? insuranceDetails.company_owner_information.subcontractors_used : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Annual Cost of Subcontractors</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.company_owner_information ? (insuranceDetails.company_owner_information.annual_cost_subcontractors ? insuranceDetails.company_owner_information.annual_cost_subcontractors : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Square Footage of Location</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.company_owner_information ? (insuranceDetails.company_owner_information.square_footage_location ? insuranceDetails.company_owner_information.square_footage_location : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='border-right-line col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-4'>
                <div className='col-12 d-flex justify-content-between mb-1'>
                  <div className='h6'>Additional Information</div>
                </div>
                {insuranceDetails.additional_information.length ?
                  <Carousel interval={null} indicators={insuranceDetails.additional_information.length === 1 ? false : true} controls={false} variant="dark">

                    {insuranceDetails.additional_information.map((additional_info, i) => (<Carousel.Item key={i}>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Prior Insurance</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{additional_info.prior_insurance ? additional_info.prior_insurance : '-'}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Length of Coverage (Months and Years)</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{additional_info.coverage_length ? additional_info.coverage_length : '-'}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>Number of Additional Insureds Needed</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{additional_info.num_additional_insureds ? additional_info.num_additional_insureds : '-'}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='insurance-details row mb-2'>
                          <div className='col-6 titles'>
                            <div>How did you hear about us?</div>
                          </div>
                          <div className='col-6 values'>
                            <div>{insuranceDetails.customer_details ? (insuranceDetails.customer_details ? (insuranceDetails.customer_details.hear_about_us_options_label ? insuranceDetails.customer_details.hear_about_us_options_label : insuranceDetails.customer_details.hear_about_us_child_options_label) : '-') : '-'}</div>
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>))}

                  </Carousel> :
                  <>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Prior Insurance</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Length of Coverage (Months and Years)</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>Number of Additional Insureds Needed</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='insurance-details row mb-2'>
                        <div className='col-6 titles'>
                          <div>How did you hear about us?</div>
                        </div>
                        <div className='col-6 values'>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                  </>
                }
              </div>
            </div>
            {showInsuranceStatusModel && <StatusUpdateModel customerInsuranceId={customerInsuranceId} showInsuranceStatusModel={showInsuranceStatusModel} setShowInsuranceStatusModel={setShowInsuranceStatusModel} initialData={{
              status: insuranceDetails.status,
              payment_link: insuranceDetails.payment_link,
              quote_link: insuranceDetails.quote_link
            }} setUpdateCount={setUpdateCount} updateCount={updateCount} />}
          </div>)
          : <div className='text-center w-100 h6'> Insurance Details Not Found</div>
      }
    </Card>
  ) : <PageLoader />;
}
