import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react'
import {
  //  useNavigate, 
  useParams
} from 'react-router-dom';
import PageLoader from '../../../loaders/PageLoader';
import ApiTypes from '../../../networking/APItypes';
import APICall from '../../../networking/AxiousServices';
import { Card } from 'react-bootstrap';
import { Badges } from '../../../constants/Badges';
// import Routes from '../../../routes';
import { commonHelper } from '../../../common/commonHelper';
import StatusUpdateModel from '../StatusUpdateModel/StatusUpdateModel';

export default function HomeOwnerView() {

  document.title = `${process.env.REACT_APP_NAME} | Home Owner Quotation Details`;
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [insuranceDetails, setInsuranceDetails] = useState(null);

  const [customerInsuranceId, setCustomerInsuranceId] = useState(null);
  const [showInsuranceStatusModel, setShowInsuranceStatusModel] = useState(false);
  const [updateCount, setUpdateCount] = useState(1);

  // const navigate = useNavigate();

  const fetchDetails = async (insurance_id) => {
    let res = await APICall({
      url: ApiTypes.insuranceDetails,
      data: {
        customer_insurance_id: insurance_id
      }
    })

    if (res.status === 1) {
      setLoading(false)
      setInsuranceDetails(res.data)
      setCustomerInsuranceId(res.data.customer_insurance_id)
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchDetails(id)
  }, [id, updateCount]);

  return !loading ? (
    <Card className="p-4 mb-4">

      {
        insuranceDetails ?
          (<div className='container-fluid p-0'>
            <div className='row mb-4'>
              <div className='col-12 d-flex justify-content-between mb-4'>
                <div className='h6'>Customer Details</div>
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <span className='quotation-status'>Quotation Status :</span> {Badges.INSURANCE_STATUS[insuranceDetails.status.toLowerCase()]}
                  </div>
                  <div className='ms-5'>
                    <button onClick={() => setShowInsuranceStatusModel(true)} className='btn btn-theme'>Update Status</button>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Customer Name</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_user_details ? (insuranceDetails.customer_user_details.display_name ? insuranceDetails.customer_user_details.display_name : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Email Address</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_user_details ? (insuranceDetails.customer_user_details.email ? insuranceDetails.customer_user_details.email : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Phone Number</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_user_details ? (insuranceDetails.customer_user_details.phone ? insuranceDetails.customer_user_details.phone : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Alternate Number</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_user_details ? (insuranceDetails.customer_user_details.alternate_phone ? insuranceDetails.customer_user_details.alternate_phone : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Address</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_user_details ? (insuranceDetails.customer_user_details.address ? insuranceDetails.customer_user_details.address : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Date Of Birth</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_user_details ? (insuranceDetails.customer_user_details.date_of_birth ? commonHelper.formatDate(insuranceDetails.customer_user_details.date_of_birth) : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>SSN</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.customer_details ? (insuranceDetails.customer_details.ssn ? insuranceDetails.customer_details.ssn : '-') : '-'}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Payment Link</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.payment_link ? insuranceDetails.payment_link : "-"}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Quotation Link</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.quote_link ? insuranceDetails.quote_link : "-"}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div className='insurance-details row mb-2'>
                  <div className='col-6 titles'>
                    <div>Agent Name</div>
                  </div>
                  <div className='col-6 values'>
                    <div>{insuranceDetails.agent_details ? <span onClick={() => {
                      // navigate(Routes.AgentView.path.replace(':id', insuranceDetails.agent_details.agent_id))
                    }} className='nav-link cursor-pointer'>{insuranceDetails.agent_details.display_name}</span> : '-'}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='border-top-line mb-4'></div>
            <div className='row'>
              <div className='border-right-line col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-4'>
                <div className='col-12 d-flex justify-content-between mb-1'>
                  <div className='h6'>Current Details</div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Do you currently have insurance?</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.customer_details ? (insuranceDetails.customer_details.has_any_insurance ? Badges.BOOLEAN[parseInt(insuranceDetails.customer_details.has_any_insurance)] : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Current Premium</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.home_current_information ? (insuranceDetails.home_current_information.current_premium ? insuranceDetails.home_current_information.current_premium : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Current Insurance Provider?</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.home_current_information ? (insuranceDetails.home_current_information.insurance_provider ? insuranceDetails.home_current_information.insurance_provider : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Months with company</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.home_current_information ? (insuranceDetails.home_current_information.months_with_company ? insuranceDetails.home_current_information.months_with_company : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Current Policy End Date</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.home_current_information ? (insuranceDetails.home_current_information.policy_end_date ? commonHelper.formatDate(insuranceDetails.home_current_information.policy_end_date) : '-') : '-'}</div>
                    </div>
                  </div>
                </div>

              </div>
              <div className='border-right-line col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-4'>
                <div className='col-12 d-flex justify-content-between mb-1'>
                  <div className='h6'>Dwelling Information</div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Year Built</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.home_dwelling_information ? (insuranceDetails.home_dwelling_information.year_built ? insuranceDetails.home_dwelling_information.year_built : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Roof Type</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.home_dwelling_information ? (insuranceDetails.home_dwelling_information.roof_type ? insuranceDetails.home_dwelling_information.roof_type : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Construction Type</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.home_dwelling_information ? (insuranceDetails.home_dwelling_information.construction_type ? insuranceDetails.home_dwelling_information.construction_type : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Date of Original Purchase</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.home_dwelling_information ? (insuranceDetails.home_dwelling_information.original_purchase_date ? commonHelper.formatDate(insuranceDetails.home_dwelling_information.original_purchase_date) : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Number of families living in home?</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.home_dwelling_information ? (insuranceDetails.home_dwelling_information.num_families ? insuranceDetails.home_dwelling_information.num_families : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Number of bedrooms?</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.home_dwelling_information ? (insuranceDetails.home_dwelling_information.liability_limit ? insuranceDetails.home_dwelling_information.liability_limit : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Liability Limit</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.home_dwelling_information ? (insuranceDetails.home_dwelling_information.year_built ? insuranceDetails.home_dwelling_information.year_built : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Deductible Amount</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.home_dwelling_information ? (insuranceDetails.home_dwelling_information.deductible_amount ? insuranceDetails.home_dwelling_information.deductible_amount : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Square Footage</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.home_dwelling_information ? (insuranceDetails.home_dwelling_information.square_footage ? insuranceDetails.home_dwelling_information.square_footage : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Estimated Value </div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.home_dwelling_information ? (insuranceDetails.home_dwelling_information.estimated_value ? insuranceDetails.home_dwelling_information.estimated_value : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Dogs</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.home_dwelling_information ? (insuranceDetails.home_dwelling_information.dogs ? insuranceDetails.home_dwelling_information.dogs : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Pool</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.home_dwelling_information ? (insuranceDetails.home_dwelling_information.pool ? insuranceDetails.home_dwelling_information.pool : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>Claims/Property Losses in Past 5 Years (Please Explain)</div>
                    </div>
                    <div className='col-6 values'>
                      <div>{insuranceDetails.home_dwelling_information ? (insuranceDetails.home_dwelling_information.claims_losses_past_5_years ? insuranceDetails.home_dwelling_information.claims_losses_past_5_years : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='insurance-details row mb-2'>
                    <div className='col-6 titles'>
                      <div>How did you hear about us?</div>
                    </div>
                    <div className='col-6 values'>
                    <div>{insuranceDetails.customer_details ? (insuranceDetails.customer_details ? (insuranceDetails.customer_details.hear_about_us_options_label ? insuranceDetails.customer_details.hear_about_us_options_label : insuranceDetails.customer_details.hear_about_us_child_options_label) : '-') : '-'}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {showInsuranceStatusModel && <StatusUpdateModel customerInsuranceId={customerInsuranceId} showInsuranceStatusModel={showInsuranceStatusModel} setShowInsuranceStatusModel={setShowInsuranceStatusModel} initialData={{
              status: insuranceDetails.status,
              payment_link: insuranceDetails.payment_link,
              quote_link: insuranceDetails.quote_link
            }} setUpdateCount={setUpdateCount} updateCount={updateCount} />}
          </div>)
          : <div className='text-center w-100 h6'> Insurance Details Not Found</div>
      }
    </Card>
  ) : <PageLoader />;
}
