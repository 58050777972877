const BaseURL = process.env.REACT_APP_API_URL;
// const RootURL = process.env.REACT_APP_ROOT_URL;


const ApiTypes = {
    login: BaseURL + 'login',
    register: BaseURL + 'register',

    agentList: BaseURL + 'agent/list',
    agentUpdateStatus: BaseURL + 'agent/status-update',
    agentStore: BaseURL + 'agent/store',
    agentDetails: BaseURL + 'agent/details/:id',
    agentUpdate: BaseURL + 'agent/update/:id',
    agentDelete: BaseURL + 'agent/delete/:id',

    insuranceList: BaseURL + 'insurance/list',
    insuranceDetails: BaseURL + 'insurance/details',
    updateInsuranceStatus: BaseURL + 'insurance/status-update',

    customerList: BaseURL + 'customers/list',
};

export default ApiTypes;