import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
// import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { alerts } from "../common/alertService";
import {
  Row,
  Col,
  Nav,
  Image,
  Navbar as BootstrapNavbar,
  Dropdown,
  Container,
  ListGroup,
} from "@themesberg/react-bootstrap";
import DefaultUserImage from "../assets/Images/defaults/default-image.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppBreadcrumb from "./AppBreadcrumb";
import Routes from "../routes";

export default function Navbar(props) {
  const UserData = useSelector((state) => state.data);
  const dispatch = useDispatch();

  let logged_user = UserData.logged_user;
  const navigate = useNavigate()
  const [notifications, setNotifications] = useState([]);
  const areNotificationsRead = notifications.reduce(
    (acc, notif) => acc && notif.read,
    true
  );

  const markNotificationsAsRead = () => {
    setNotifications([]);
    // setTimeout(() => {
    //   setNotifications(notifications.map((n) => ({ ...n, read: true })));
    // }, 300);
  };

  const handleLogout = () => {
    localStorage.removeItem('user_id')
    localStorage.removeItem('auth_token')
    localStorage.removeItem('user')
    dispatch({ type: 'USER_ID', payload: null })
    dispatch({ type: 'AUTH_TOKEN', payload: null })
    dispatch({ type: 'LOGGED_USER', payload: null })
    alerts.notifySuccess("You are logged out successfully.")
    navigate(Routes.Signin.path)
  }

  const Notification = (props) => {
    const { link, sender, image, time, message, read = false } = props;
    const readClassName = read ? "" : "text-danger";

    return (
      <ListGroup.Item action href={link} className="border-bottom border-light">
        <Row className="align-items-center">
          <Col className="col-auto">
            <Image
              src={image}
              className="user-avatar lg-avatar rounded-circle"
            />
          </Col>
          <Col className="ps-0 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{sender}</h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>{time}</small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{message}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (<>
    <BootstrapNavbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            {/* <Form className="navbar-search">
              <Form.Group id="topbarSearch">
                <InputGroup className="input-group-merge search-bar">
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                  <Form.Control type="text" placeholder="Search" />
                </InputGroup>
              </Form.Group>
            </Form> */}
          </div>
          <Nav className="align-items-center">
            <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead}>
              <Dropdown.Toggle
                as={Nav.Link}
                className="text-dark icon-notifications me-lg-3"
              >
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faBell} className="bell-shake" />
                  {areNotificationsRead ? null : (
                    <span className="icon-badge rounded-circle unread-notifications" />
                  )}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                  <Nav.Link
                    href="#"
                    className="text-center text-primary fw-bold border-bottom border-light py-3"
                  >
                    Notifications
                  </Nav.Link>

                  {notifications.length ? (
                    notifications.map((n) => (
                      <Notification key={`notification-${n.id}`} {...n} />
                    ))
                  ) : (
                    <div className="text-center mt-4 mb-4 fw-bold">
                      Notifications not found!
                    </div>
                  )}

                  {notifications.length ? (
                    <Dropdown.Item className="text-center text-primary fw-bold py-3">
                      View all
                    </Dropdown.Item>
                  ) : (
                    ""
                  )}
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <Image
                    src={
                      logged_user.profile_img
                        ? process.env.REACT_APP_ROOT_URL +
                        logged_user.profile_img
                        : DefaultUserImage
                    }
                    className="user-avatar md-avatar rounded-circle"
                  />
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">
                      {logged_user.display_name}
                    </span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                {/* <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My
                  Profile
                </Dropdown.Item>
                <Dropdown.Divider /> */}
                <Dropdown.Item onClick={handleLogout} className="fw-bold">
                  <FontAwesomeIcon
                    icon={faSignOutAlt}
                    className="text-danger me-2"
                  />
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>

    </BootstrapNavbar>
    <Container fluid className="px-0">
      <AppBreadcrumb />
    </Container>
  </>);
}
