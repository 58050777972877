import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import InputElement from "../../../components/Forms/InputElement";
import { Options } from "../../../constants/Options";
import SelectInput from "../../../components/Forms/SelectInput";
import ApiTypes from "../../../networking/APItypes";
import APICall from "../../../networking/AxiousServices";
import { alerts } from "../../../common/alertService";

export default function StatusUpdateModel({
  customerInsuranceId,
  showInsuranceStatusModel,
  setShowInsuranceStatusModel,
  initialData,
  setUpdateCount,
  updateCount,
}) {
  const [stateData, setStatusData] = useState(initialData);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setLoading(false);
    setShowInsuranceStatusModel(false);
  };

  const handleOnChange = (name, value) => {
    setStatusData({
      ...stateData,
      [name]: value,
    });
  };

  const updateStatus = async (e) => {
    setLoading(true);
    e.preventDefault();
    let res = await APICall({
      method: "PUT",
      url: ApiTypes.updateInsuranceStatus,
      data: {
        customer_insurance_id: customerInsuranceId,
        ...stateData,
      },
    });
    if (res.status === 1) {
      setUpdateCount(updateCount + 1);
      handleClose();
      alerts.notifySuccess(res.message);
    } else {
      setLoading(false);
    }
  };

  return (
    <Modal show={showInsuranceStatusModel} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Quotation Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <SelectInput
            inputGroupClass={"col-12"}
            is_required={true}
            placeholder={"Select Status"}
            selected={
              Options.insurance_status.filter(
                (itm) => itm.value === stateData.status.toLowerCase()
              )[0]
            }
            Label={"Select Status"}
            options={Options.insurance_status}
            onChange={(item) => {
              handleOnChange("status", item.value);
            }}
            validateError={""}
          />
          <InputElement
            inputGroupClass={"col-12"}
            Label={"Payment Link"}
            value={stateData.payment_link}
            name="payment_link"
            onChange={(name, value) => {
              handleOnChange(name, value);
            }}
            type="text"
            placeholder="Last Name"
            validateError={""}
          />
          <InputElement
            inputGroupClass={"col-12"}
            Label={"Quotation Link"}
            value={stateData.quote_link}
            name="quote_link"
            onChange={(name, value) => {
              handleOnChange(name, value);
            }}
            type="text"
            placeholder="Last Name"
            validateError={""}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="info" onClick={handleClose}>
          Close
        </Button>
        {loading ? (
          <Button variant="theme" type="button" className={"w-auto"}>
            <Spinner size="sm" animation="border" />{" "}
            <span className="ms-2">Loading...</span>
          </Button>
        ) : (
          <Button
            onClick={(e) => updateStatus(e)}
            variant="theme"
            type="button"
            className={"w-auto"}
          >
            Update
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
